let devUrl = "192.168.1.9:8000";
let prodUrl = "api.uimire.com";
export let baseUrl = "https://" + prodUrl;
export let wsUrl = "wss://" + prodUrl;

// try to ping devUrl, if it's available, use it
if (process.env.NODE_ENV === "development") {
  $fetch("http://" + devUrl)
  .then((r) => {
    baseUrl = "http://" + devUrl;
    wsUrl = "ws://" + devUrl;
    // console.log("Development API");
  })
  .catch((e) => {
    // console.log("Production API")
  });
}

function errorToast(){
  const toast = useToast();
  toast.add({
    title: "Error, try again later.",
    icon: "i-heroicons-information-circle",
    color: "red",
  });
}

export default function useApi(
  method: string,
  url: string,
  data: any = null,
  catch401 = true
): any {

  if (typeof url == "string") {
    url = baseUrl + url
  }

  const store = useStore();

  return $fetch(url, {
    method: method as any,
    body: data,
    headers: {
      Authorization: "Bearer " + store.token,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((r: any) => {
      if (r.error) {
        errorToast();
      }
      return r;
    })
    .catch((e: any) => {
      if (!catch401) {
        errorToast();
      }

      if (!e || !e.response || !e.response.status) {
        return false;
      }
      // else if (e.response.status === 401 && catch401) {
      //   navigateTo(`/auth/login`);
      // }
      else if (e.response.status === 403) {
        console.log("403: Entity not enabled");
      } else if (e.response.status === 422) {
        console.log("422: Invalid data");
      }

      return false;
    });
}
