<script setup lang="ts">
const store = useStore();
const colorMode = useColorMode();
const color = computed(() =>
  colorMode.value === "dark" ? "#151515" : "white"
);

if (import.meta.client) {
  store.init();
}

useHead({
  meta: [
    { charset: "utf-8" },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    { key: "theme-color", name: "theme-color", content: color },
  ],
  link: [{ rel: "icon", href: "/favicon.ico" }],
  htmlAttrs: {
    lang: "ro",
  },
});

useSeoMeta({
  title: "Uimire Company",
  description: "Uimire Company",
  ogTitle: "Uimire Company",
  ogDescription: "Uimire Company",
  // ogImage: 'https://dashboard-template.nuxt.dev/social-card.png',
  // twitterImage: 'https://dashboard-template.nuxt.dev/social-card.png',
  twitterCard: "summary_large_image",
});
</script>

<template>
  <VitePwaManifest />

  <NuxtLayout />

  <UModals />
</template>
